import React from 'react';
import './style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import About from './About';
import Faq from './pages/Faq';
import Media from './pages/Media';
import Education from './pages/Education';
import Product from './pages/Product';
import Heritage from './pages/Heritage';
// import FilmComp from './pages/FilmComp';
import NavHeader from './NavHeader';
import Footer from './EndFooter';
import ScrollToTop from './components/ScrollToTop';


const App = () => (
  <Router>
    <NavHeader />
    <Routes>
      <Route exact path="/" element={<About />} />
    </Routes>
    <ScrollToTop />
    {/* <FilmComp /> */}
    <Media />
    <Education />
    <Product />
    <Heritage />
    <Faq />
    <Footer />
  </Router>
);

export default App;